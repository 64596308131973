<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <!-- <h1>Fee Payment</h1> -->
                <v-flex xs12>
                    <div style="width: 300px; margin: 30px auto;">
                        <v-text-field label="Enroll Code" box v-model="enrollCode" clearable hint="Type in enroll code to get started..." type="text" append-outer-icon="search"/></div>
                </v-flex>
                <v-flex xs12>
                    <div class="invoice">
                        <table cellpadding="0" cellspacing="0">
                            <tr>
                                <td><img src="https://eacademy.sgp1.digitaloceanspaces.com/eacademy/5/school/r5L7nUpTkwVv38Iw8auSuojt6lo50QgINdKhyEtE.png" alt="" style="width: 100px"></td>
                                <td style="text-align: right;"><strong style="font-size: 24px;">INVOICE</strong></td>
                            </tr>
                        </table>
                        <table cellpadding="0" cellspacing="0" style="margin-top: 10px;">
                            <tr>
                                <td style="font-size: 20px;"><strong>Client Name</strong></td>
                                <td style="text-align: right;">Your Name<br>Your Address</td>
                            </tr>
                            <tr>
                                <td>Date Issued: <b>04 May'16</b></td>
                                <td style="text-align: right;">Town, City<br>Postcode</td>
                            </tr>
                        </table>
                        <table cellpadding="0" cellspacing="0" class="table-content">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th style="text-align: center;">Rate</th>
                                    <th style="text-align: center;">Dues</th>
                                    <th style="text-align: right; color: red;">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Website design</td>
                                    <td style="text-align: center;"><strong>12.33</strong></td>
                                    <td style="text-align: center;"><strong>12.33</strong></td>
                                    <td style="text-align: right; color: red;"><strong>12.33</strong></td>
                                </tr>
                                <tr>
                                    <td>Website design</td>
                                    <td style="text-align: center;"><strong>12.33</strong></td>
                                    <td style="text-align: center;"><strong>12.33</strong></td>
                                    <td style="text-align: right; color: red;"><strong>12.33</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="footer-div">
                            <table class="footer" cellpadding="0" cellspacing="0">
                                <thead>
                                    <tr>
                                        <td>Bank Info</td>
                                        <td>Due By</td>
                                        <td style="text-align: right;">Total Due</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="width: 33%;">
                                            Account No: <strong>123 456 7890</strong><br>
                                            Sort Code: <strong>01 23 45</strong>
                                        </td>
                                        <td style="width: 34%;">
                                            <span style="font-size: 25px; font-weight: bolder;">18 May '16</span>
                                        </td>
                                        <td style="width: 33%; text-align: right;">
                                            <span style="font-size: 25px; font-weight: bolder;">2468.00</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </v-flex>
                <!-- <v-card>
                    <v-card-title class="title pt-1 pb-1">
                        Fee Payment
                        <v-spacer></v-spacer>
                        <span style="width: 200px;"><v-text-field v-model="enrollCode" label="Enroll Code"></v-text-field></span>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutRight">
                            <v-btn outlined @click="get" ma-0 small v-if="enrollCode && enrollCode.length >= 8" >Get Details</v-btn>
                        </transition>
                    </v-card-title>
                    <v-data-table :headers="headers" hide-default-footer :items="form.items.data" :options.sync="pagination" :server-items-length="form.items.meta.total" :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <td class="text-xs-left">{{ props.index + 1}}</td>
                            <td class="text-xs-left">{{ props.item.fee_head.title }}</td>
                            <td class="text-xs-left">{{ props.item.amount.currency() }}</td>
                            <td class="text-xs-left">{{ (props.item.generate_bill == 1) ? 'True' :'False'  }}</td>
                            <td class="text-xs-left">{{ (props.item.is_waive_off == 1) ?'True':'False'  }}</td>
                            <td class="text-xs-right">
                                <edit-button permission="edit-routine" @agree="form.edit(props.item)" />
                                <delete-button permission="delete-routine" @agree="form.delete(props.item.id)" />
                            </td>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer />
                        <v-pagination v-if="form.items.data.length > 0" v-model="pagination.page" :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer />
                    </v-card-actions>
                </v-card> -->
            </v-flex>
            <!-- <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save" @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save" lazy-validation>
                            <v-layout row wrap>
                                <v-flex xs12 sm12>
                                    <v-text-field autocomplete="off" label="Amount*" required class="pa-0 pl-2" v-model="form.amount" name="amount" :error-messages="form.errors.get('amount')" type="number" />
                                </v-flex>
                                <v-flex xs6 sm6>
                                    <v-checkbox v-model="form.generate_bill" label="Generate Bill"/>
                                </v-flex>
                                <v-flex xs6 sm6>
                                    <v-checkbox v-model="form.is_waive_off" label="Waive"/>
                                </v-flex>
                            </v-layout>
                            <small>*indicates required field</small>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->
        </v-layout>
    </v-container>
</template>
<style lang="scss" scoped>
    .invoice {
        background: white;
        width: 800px;
        margin: auto;
        border: 1px solid black;
        padding: 25px 25px 0;
        table {
            width: 100%;
            thead {
                th {
                    text-align: left;
                    text-transform: uppercase;
                    border-bottom: 1px solid black;
                }
            }
            &.table-content {
                margin-top: 50px;
                td, th {
                    padding: 10px;
                }
                margin-bottom: 100px;
            }
            &.footer {
                thead {
                    td, th {
                        border-bottom: 1px solid #fcfcfc;
                        padding-bottom: 4px;
                    }
                }
                tbody {
                    tr {
                        td {
                            padding: 20px 0;
                        }
                    }
                }
            }
        }
        .footer-div {
            background: #D0CCC5;
            margin-right: -25px;
            margin-left: -25px;
            padding: 25px;
        }
    }
</style>
<script>
import { mapState } from 'vuex'
import Form from '@/library/Form'

export default {
    data: () => ({
        form: new Form({
            fee_head_id: '',
            gradeId: '',
            fee_month: [],
            batchId: '',
            amount: '',
            generate_bill: 0,
            is_waive_off: 0,
        }, '/api/fee-payment'),
        enrollCode: null,
        pagination: {
            rowsPerPage: 20
        },
        headers: [
            { text: 'S.No', align: 'left', value: 'id', width: 50, sortable: false },
            { text: 'Title', align: 'left', value: 'title', sortable: false },
            { text: 'Amount', align: 'left', value: 'fee_head_amount', sortable: false },
            { text: 'Discount', align: 'left', value: 'discount', sortable: false },
            { text: 'Scholarship', align: 'left', value: 'scholarship', sortable: false },
            { text: 'Total', align: 'left', value: 'total', sortable: false },
            { text: 'Paid Ammount', align: 'left', value: 'paid_amount', sortable: false },
            { text: 'Due Amount', align: 'left', value: 'due_amount', sortable: false },
            { text: 'Action', align: 'left', value: 'action', sortable: false },
        ]
    }),

    computed: {
        ...mapState(['batch']),
    },

    mounted() {
    },

    watch: {
        'pagination': function() {
            this.get();
        },
        'batch': function(value) {
            this.get();
        },
        'enrollCode':function(value) {
            if(value.length > 7 ) this.getBill();
        }
    },

    methods: {
        queryString() {
            let json = this.pagination;
            return '?' +
                Object.keys(json).map(function(key) {
                    if (![null, undefined].includes(json[key]))
                        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&') + '&batchId=' + this.batch.id + '&gradeId=' + this.grade;
        },

        get() {
            console.log('test');
            // if ([undefined, null, ''].includes(this.enrollCode) || this.enrollCode.length < 8) return;
            if (this.grade) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({ data }) => {
                    console.log(data)
                })
            }
        },
        getBill()
        {
            this.$rest.get('/api/fee-payment/'+this.enrollCode).then(({ data }) => {
                console.log(data);
                this.pagination.totalItems = data.meta.total

            })
         },

        searchable() {
            console.log(this.enrollCode);
            if([undefined, null, ''].includes(this.enrollCode) || this.enrollCode.length < 8) return false;
            return true;
        },

        save() {},
    }
}
</script>